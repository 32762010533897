import React from "react"

const Header = ({ title, subtitle }) => {
    return (
        <React.Fragment>
            {/* <div className="main-header-deco1"></div> */}
            <div className="main-header">
                <h1>{title}</h1>
                <span className="main-header-subtitle">{subtitle}</span>
            </div>
            <div className="main-header-deco2"></div>
        </React.Fragment>
    )
}

export default Header
