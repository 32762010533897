import React from "react"
import { Link } from "gatsby"
// import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
// This ensures that the icon CSS is loaded immediately before attempting to render icons
// import "@fortawesome/fontawesome-svg-core/styles.css" //moved to gatsby-browser.js
import { config } from "@fortawesome/fontawesome-svg-core"
// Prevent fontawesome from dynamically adding its css since we did it manually above
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons" //TODO use this to change the menu bar icons!
import {
    faTwitterSquare,
    faInstagram,
    faDiscord,
    // faRedditSquare,
    faTwitch,
} from "@fortawesome/free-brands-svg-icons"
import logo from "../images/Spacehead Milky Way White Logo SVG.svg"
import logoLong from "../images/Spacehead Long Milky Way White Logo SVG.svg"
config.autoAddCss = false

const NavLink = styled(Link)`
    font-size: calc(20px + (32 - 20) * ((100vw - 300px) / (1600 - 300)));
    color: var(--milky-way-white);
    text-decoration: none;
    text-transform: uppercase;
    user-select: none;
    margin: 1em 0;

    &:hover {
        text-decoration: none;
        color: var(--racecar-orange);
        -webkit-animation: glow 1s ease-in-out infinite alternate;
        -moz-animation: glow 1s ease-in-out infinite alternate;
        animation: glow 1s ease-in-out infinite alternate;
    }

    @keyframes glow {
        from {
            text-shadow: 0 0 5px var(--racecar-orange);
        }
        to {
            text-shadow: 0 0 20px var(--racecar-orange);
        }
    }
`

const NavSocials = styled.div`
    margin-top: 2em;
    display: flex;
    flex-direction: row wrap;
    justify-content: space-between;

    /* @media (min-width: 576px) {
        justify-content: left;
    } */
`
export const NavSocialLink = styled.a`
    color: var(--milky-way-white);
    text-decoration: none;
    position: relative;

    letter-spacing: 2px;
    text-transform: capitalize;
    font-size: calc(24px + (32 - 24) * ((100vw - 300px) / (1600 - 300)));
    /* padding-right: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300))); */

    &:hover,
    &:focus,
    &:active {
        color: var(--racecar-orange);
    }

    @media (min-width: 576px) {
        /* padding-right: calc(
            16px + (20 - 16) * ((100vw - 300px) / (1600 - 300))
        ); */
    }
`

const Sidebar = ({ data, children, id }) => {
    // const [hamburger, toggleHamburger] = useState(false)
    // const [modalWidth, toggleModalWidth] = useState(false)

    // const handleToggleHamburger = () => {
    //     toggleHamburger(!hamburger)
    //     toggleModalWidth(!modalWidth)
    // }
    return (
        //<StaticQuery
        // query={graphql`
        //     query HeaderQuery {
        //         prismicConfig {
        //             data {
        //                 facebook_url
        //                 instagram_url
        //                 youtube_url
        //                 twitter_url
        //                 defaultSiteTitle: meta_site_title
        //             }
        //         }
        //     }
        // `}
        //render={data => (
        <aside className="col-12 col-xl-2 nav-aside p-0">
            <nav className="navbar navbar-expand-xl navbar-dark align-items-start flex-xl-column flex-row nav-wrapper">
                <div className="nav-header">
                    {/*TODO change to button */}
                    <a
                        href=""
                        className="navbar-toggler"
                        data-toggle="collapse"
                        data-target=".sidebar"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </a>
                    <Link className="navbar-brand" to="/">
                        <img
                            src={logoLong}
                            width="100%"
                            alt="Spacehead logo"
                            className="d-mobile"
                        />
                        <img
                            src={logo}
                            width="100%"
                            alt="Spacehead logo"
                            className="d-desktop"
                        />
                    </Link>
                </div>

                <div className="collapse navbar-collapse sidebar flex-column justify-content-between w-100">
                    <p className="nav-subtitle mt-2 t-orange t-fluid-16-20">
                        CITIZENCON 2949
                    </p>

                    <ul className="flex-column navbar-nav w-100 justify-content-between mt-4 mb-4">
                        <li className="nav-item">
                            <NavLink
                                to="/schedule"
                                activeClassName="nav-link-active"
                            >
                                Schedule
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/stream"
                                activeClassName="nav-link-active"
                            >
                                Stream
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/videos"
                                activeClassName="nav-link-active"
                            >
                                Videos
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/expo"
                                activeClassName="nav-link-active"
                            >
                                Expo
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/about"
                                activeClassName="nav-link-active"
                            >
                                About
                            </NavLink>
                        </li>
                    </ul>

                    <div className="nav-footer">
                        <p className="small">
                            <a className="link" href="/about">
                                CONTACT US
                            </a>{" "}
                            |{" "}
                            <a className="link" href="/contribute">
                                CONTRIBUTE
                            </a>
                        </p>
                        <NavSocials>
                            <NavSocialLink
                                //href={data.prismicConfig.data.youtube_url}
                                href="#"
                                target="_blank"
                            >
                                <FontAwesomeIcon icon={faDiscord} />
                            </NavSocialLink>
                            <NavSocialLink
                                //href={data.prismicConfig.data.youtube_url}
                                href="#"
                                target="_blank"
                            >
                                <FontAwesomeIcon icon={faTwitterSquare} />
                            </NavSocialLink>
                            <NavSocialLink
                                //href={data.prismicConfig.data.youtube_url}
                                href="#"
                                target="_blank"
                            >
                                <FontAwesomeIcon icon={faInstagram} />
                            </NavSocialLink>
                            {/* <NavSocialLink
                                    //href={data.prismicConfig.data.youtube_url}
                                    href="#"
                                    target="_blank">
                                    <FontAwesomeIcon
                                        icon={faRedditSquare}
                                    />
                                </NavSocialLink> */}
                            <NavSocialLink
                                //href={data.prismicConfig.data.youtube_url}
                                href="#"
                                target="_blank"
                            >
                                <FontAwesomeIcon icon={faTwitch} />
                            </NavSocialLink>
                        </NavSocials>
                        <p className="small mt-4">
                            (c) 2019 SPACEHEAD
                            <br />
                            BY SPACEHEAD AEROSPACE
                        </p>
                    </div>
                </div>
            </nav>
        </aside>
        //)}
        ///>
    )
}

export default Sidebar
