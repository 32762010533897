import React from "react"
import { createGlobalStyle } from "styled-components"
import Sidebar from "./Sidebar"
import Header from "./Header"
// import Footer from "./Footer"
// import SEO from "./SEO"
import bgCrt from "../images/bg/spacehead_bg_crt.png"
import bgPlanets from "../images/bg/spacehead_bg_planets.png"

const GlobalStyle = createGlobalStyle`
    :root {
    --main-bg-color: #071019;
    --space-blue: #071019;
    --milky-way-white: #f2eedd;
    --racecar-orange: #ff4905;
    --cosmic-blue: #0072bc;
    }

    html {
        box-sizing: border-box;
        font-size: 16px;
    }

    body {
        height: 100vh;
        background-color: var(--main-bg-color);
        /* background-image: url('data:image/svg+xml,%3Csvg width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M8 16c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zm33.414-6l5.95-5.95L45.95.636 40 6.586 34.05.636 32.636 2.05 38.586 8l-5.95 5.95 1.414 1.414L40 9.414l5.95 5.95 1.414-1.414L41.414 8zM40 48c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zM9.414 40l5.95-5.95-1.414-1.414L8 38.586l-5.95-5.95L.636 34.05 6.586 40l-5.95 5.95 1.414 1.414L8 41.414l5.95 5.95 1.414-1.414L9.414 40z" fill="white" fill-opacity="1" fill-rule="evenodd"/%3E%3C/svg%3E'); */
        background-image: url('${bgPlanets}'), url('${bgCrt}');
        background-repeat: no-repeat, repeat;
        background-position: bottom left, left;
        /* background-size: 25%, 100%; */
        font-family: Aldrich, sans-serif !important; /* TODO TEST BOOTSTRAP OVERRIDING */
        color: var(--milky-way-white);
    }

    body::-webkit-scrollbar {
        width: 1em;
        background: var(--space-blue);
    }
    
    body::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    }
    
    body::-webkit-scrollbar-thumb {
        background-color: var(--racecar-orange);
        outline: 1px solid white;
    }

    *, *:before, *:after {
        box-sizing: inherit;
    }

    body, h1, h2, h3, h4, h5, h6, p, ol, ul {
        margin: 0;
        padding: 0;
        font-weight: normal;
        line-height: 1.5;
    }

    p, .p {
        font-size: 1rem;
        line-height: 1.5rem;
        margin-bottom: 1.25em;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 2.75rem 0 1rem;
        font-weight: 400;
        text-transform: uppercase;
    }
    
    h1 {
        margin: 0;
        /* line-height: 2.25rem; */
        /* font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]))); */
        font-size: calc(24px + (54 - 24) * ((100vw - 300px) / (1600 - 300)));
        line-height: 2rem; /*TEST*/
    }

    .display1 {
        font-size: calc(24px + (54 - 24) * ((100vw - 300px) / (1600 - 300)));
        margin: 2.75rem 0 1rem;
    }

    h2, .display2 {
        font-size: calc(20px + (36 - 20) * ((100vw - 300px) / (1600 - 300)));
        /* font-size: 2.25em; */
        /* line-height: 3.375rem; */
        line-height: calc(24px + (54 - 24) * ((100vw - 300px) / (1600 - 300)));
        text-shadow: 0 0 5px var(--racecar-orange);
    }

    h2.ts-0, .display2.ts-0 {
        text-shadow: none;
    }

    h3, .display3 {
        font-size: calc(16px + (32 - 16) * ((100vw - 300px) / (1600 - 300)));
        /* font-size: 2em; */
        /* line-height: 3.375rem; */
        line-height: calc(24px + (48 - 24) * ((100vw - 300px) / (1600 - 300)));
    }

    h4, .display4 {
        font-size: calc(16px + (24 - 16) * ((100vw - 300px) / (1600 - 300)));
        /* font-size: 1.5em; */
        /* line-height: 2.25rem; */
        line-height: calc(24px + (36 - 24) * ((100vw - 300px) / (1600 - 300)));
    }

    h5, .display5 {
        font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
        /* font-size: 1.25em; */
        /* line-height: 1.875rem; */
        line-height: calc(24px + (30 - 24) * ((100vw - 300px) / (1600 - 300)));
    }

    h6, .display6 {
        /* font-size: calc(24px + (54 - 24) * ((100vw - 300px) / (1600 - 300))); */
        font-size: 1em;
        line-height: 1.5rem;
    }

    small {font-size: 0.667em;}

    /* a {
        text-decoration: none;
        color: inherit;
        font-weight: inherit;
        padding-bottom: 5px;
        border-bottom: 1px solid;

        &:hover,
        &:focus,
        &:active {
            color: #00aaff;
            border-bottom: 3px solid;
        }
    } */
    
    /* ol, ul {
        list-style: none;
    } */

    img {
        max-width: 100%;
        height: auto;
    }

    .display-none {
        display: none;
    }

    .display-block {
        display: block;
    }


    a.link {
        color: var(--milky-way-white);
    }

    a.link:hover {
        text-decoration: none;
        color: var(--racecar-orange);
        -webkit-animation: glow 1s ease-in-out infinite alternate;
        -moz-animation: glow 1s ease-in-out infinite alternate;
        animation: glow 1s ease-in-out infinite alternate;
    }

    @keyframes glow {
        from {
            text-shadow: 0 0 5px var(--racecar-orange);
        }
        to {
            text-shadow: 0 0 20px var(--racecar-orange);
        }
    }

    /* .nav-wrapper {
        min-height: 100vh; 
        max-height: 100%;
    } */

    main {
        max-height: 100%; /* TODO TEST */
        overflow-y: auto; /* TODO TEST */
    }

    .navbar.nav-wrapper {
        padding: 1.5em;
    }

    /* .navbar {
        height: 100%;
    } */

    .navbar-toggler {
        border-color: var(--racecar-orange) !important;
    }
    .navbar .nav-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-basis: 100%;
    }

    /* .navbar .nav-item > a {
        font-size: calc(16px + (32 - 16) * ((100vw - 300px) / (1600 - 300)));
        color: var(--milky-way-white);
    } */

    /* a.nav-link span {
        text-shadow: 0 0 5px white;
    } */
    .nav-link-active {
        color: var(--racecar-orange);
        -webkit-animation: glow 1s ease-in-out infinite alternate;
        -moz-animation: glow 1s ease-in-out infinite alternate;
        animation: glow 1s ease-in-out infinite alternate;
    }

    @keyframes glow {
        from {
            text-shadow: 0 0 5px var(--racecar-orange);
        }
        to {
            text-shadow: 0 0 20px var(--racecar-orange);
        }
    }
    /* .nav-footer {
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    } */

    /* .navbar-collapse {
        flex-grow: 0;
    } */

    /* Extra small devices (portrait phones, less than 576px)
    No media query since this is the default in Bootstrap */

    /* Small devices (landscape phones, 576px and up) */
    .navbar .collapse {
        align-items: flex-end;
    }

    .navbar-brand {
        margin-right: 0;
        margin-left: 1rem;
    }

    .d-mobile {
        display: block;
    }

    .d-desktop {
        display: none;
    }

    .t-orange {
        color: var(--racecar-orange);
    }

    .t-fluid-16-20 {
        font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1600 - 300)));
    }
    
    .t-fluid-16-32 {
        font-size: calc(16px + (32 - 16) * ((100vw - 300px) / (1600 - 300)));
    }

    main {
        padding: 0!important;
    }

    main.col {
        flex-basis: 100% !important;
    }

    main .content {
        padding: 1.5em!important;
    }

    main .main-header {
        background-color: var(--racecar-orange);
        width: 100%;
        padding: 1.5em;
        display: flex;
        flex-flow: column wrap;
        align-items: flex-end;
        align-self: flex-end;
        text-transform: uppercase;
        text-align: right;
    }

    main .main-header > h2 {
        line-height: calc(32px + (52 - 32) * ((100vw - 300px) / (1600 - 300)));
        font-size: calc(24px + (52 - 24) * ((100vw - 300px) / (1600 - 300)));
        margin: 0;
        
    }

    main .main-header-deco2 {
        height: 35px;
        width: 75%;
        margin-top: 1em;
        background: repeating-linear-gradient(
            45deg,
            transparent,
            transparent 10px,
            var(--milky-way-white) 0px,
            var(--milky-way-white) 15px
        );
        display: flex;
        flex-flow: column wrap;
        align-self: flex-end;
    }

    main .main-header-subtitle {
        font-size: calc(16px + (24 - 16) * ((100vw - 300px) / (1600 - 300)));
        color: #000000;
    }
    @media (min-width: 576px) {
    }

    /* Medium devices (tablets, 768px and up) */
    @media (min-width: 768px) {
    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {
    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
        h1 {
            line-height: 3.5rem;
        }
        main.col {
            flex-basis: 0 !important;
        }

        main .content {
            padding: 2em!important;
        }

        main .main-header {
            margin-top: 2rem;
            width: 75%;
        }

        main .main-header-deco1 {
            height: 50px;
            position: absolute;
            width: 85%;
            background: repeating-linear-gradient(
                -45deg,
                transparent,
                transparent 14px,
                var(--milky-way-white) 14px,
                var(--milky-way-white) 15px
            );
            display: flex;
            flex-flow: column wrap;
            align-self: flex-end;
        }

        main .main-header-deco2 {
            height: 35px;
            width: 50%;
        }

        .navbar.nav-wrapper {
            /* position: fixed; */
            padding: 2em;
        }

        .navbar .collapse {
            align-items: flex-start;
        }

        .navbar-brand {
            margin: 0%;
        }

        .d-mobile {
            display: none;
        }

        .d-desktop {
            display: block;
        }

        .navbar .nav-header {
            display: flex;
            flex-direction: column;
            justify-content: start;
            flex-basis: auto;
        }

        .nav-wrapper {
            min-height: 100vh; 
            max-height: 100%;
        }
    }

    .header-m-0 {
        margin: 0;
    }
    
    .header-mt-0 {
        margin-top: 0;
    }

`
export default ({ data, children, headerTitle, headerSubtitle }) => (
    <React.Fragment>
        {/* <SEO /> */} {/*TODO bring back */}
        <GlobalStyle />
        <div className="container-fluid">
            <div className="row">
                <Sidebar />
                <main className="col d-flex flex-column">
                    <Header title={headerTitle} subtitle={headerSubtitle} />
                    <section className="content d-flex flex-column">
                        {children}
                    </section>
                </main>
            </div>
        </div>
    </React.Fragment>
)
